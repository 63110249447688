/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({
	screenWidth: 0,
});

const state = {
	...getDefaultState(),
};

const getters = {
	isMobile: ({ screenWidth }) => screenWidth <= 1024,
};

const mutations = {
	// mobile - desktop checker
	updateScreenWidth(state, { screenWidth }) {
		state.screenWidth = screenWidth;
	},
	updateScreenHeight(state, { screenHeight }) {
		state.screenHeight = screenHeight;
	},
};

const actions = {
	updateScreenWidth({ commit }, { screenWidth }) {
		commit('updateScreenWidth', { screenWidth });
	},
	updateScreenHeight({ commit }, { screenHeight }) {
		commit('updateScreenHeight', { screenHeight });
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
};
