<template>
	<v-footer class="footer-container">
		<div class="socials text-center d-flex flex-column">
			<div>
				<v-btn
					text
					color="white"
					v-for="(icon, index) in icons"
					:key="index"
					class="mx-6 mt-3 mb-6 socials-button"
					@click="handleIconClick(icon)"
				>
					<v-icon color="#f7f4f9">{{ icon.icon }}</v-icon>
				</v-btn>
			</div>
			<div style="color: #f7f4f9">
				<span>Mario A. Isamitt Pualuan</span> <strong>@2023</strong>
			</div>
		</div>
	</v-footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				icons: [
					{ link: 'tel:+566004010019', icon: 'mdi-phone' },
					{
						link: 'https://www.linkedin.com/in/mario-isamitt-pualuan-26283a141',
						icon: 'mdi-linkedin',
					},
					{
						link: 'https://www.instagram.com/mario.isamitt/',
						icon: 'mdi-instagram',
					},
					{ link: 'mailto:medico@marioisamitt.com', icon: 'mdi-email-outline' },
					{
						link: 'https://www.facebook.com/mario.isamitt/',
						icon: 'mdi-facebook',
					},
				],
			};
		},
		methods: {
			handleIconClick(icon) {
				if (icon.link.startsWith('mailto:')) {
					window.location.href = icon.link; // Open mail application for mailto link
				} else if (icon.link.startsWith('tel:') && this.isMobileDevice()) {
					window.location.href = icon.link; // Initiate phone call for tel link on mobile device
				} else {
					window.open(icon.link, '_blank'); // Open link in a new tab
				}
			},
			isMobileDevice() {
				const mobileRegex =
					/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
				return mobileRegex.test(navigator.userAgent);
			},
		},
	};
</script>

<style scoped>
	.footer-container {
		display: flex;
		flex-direction: column;
		padding: 0;
	}
	.socials {
		gap: 10%;
		padding: 3% 1%;
		background: var(--color-primary);
		width: 100%;
	}
</style>
