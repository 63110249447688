<template>
	<div class="home" style="color: var(--color-primary)">
		<div style="color: var(--color-primary); padding: 0% 5%">
			<h2 style="font-weight: 400">Página web en construcción</h2>
			<br />
			<div style="font-weight: 200">Teléfono Diagnomed: 600 401 0019</div>
			<div style="font-weight: 200">Correo: medico@marioisamitt.com</div>
		</div>
		<div
			v-if="!isMobile"
			class="d-flex row"
			style="padding: 5%; height: max-content"
		>
			<div style="padding: 1%">
				<img
					src="../assets/images/mario_isamitt.png"
					style="border-radius: 15px; max-width: 500px !important"
					alt=""
				/>
			</div>
			<div
				style="
					padding: 7%;
					text-align: justify;
					color: var(--color-secondary);
					font-weight: 400;
				"
			>
				<p>
					Mi norte, como médico, es acercar la salud a las personas. A través de
					la promoción de hábitos saludables —deporte, nutrición y consciencia—,
					y su correcta aplicación, busco elevar tu bienestar físico, mental y
					social.
				</p>
				<p>
					Durante la consulta me esforzaré en encontrar el origen de tu
					dolencia. Una vez identificada la causa te enseñaré a comprenderla
					para que, cuando sea posible, puedas corregir con tus propias
					herramientas aquello que te está enfermando.
				</p>
				<p>
					Me comprometo a escucharte con atención y tratarte con respeto. Me
					sentiré feliz y agradecido si logro ayudarte.
				</p>
			</div>
		</div>
		<div v-else style="width: 100%; height: max-content !important">
			<div
				style="
					padding: 10% 10% 0% 10%;
					text-align: justify;
					color: var(--color-secondary);
					font-weight: 400;
				"
			>
				<p>
					Mi norte, como médico, es acercar la salud a las personas. A través de
					la promoción de hábitos saludables —deporte, nutrición y consciencia—,
					y su correcta aplicación, busco elevar tu bienestar físico, mental y
					social.
				</p>
				<p>
					Durante la consulta me esforzaré en encontrar el origen de tu
					dolencia. Una vez identificada la causa te enseñaré a comprenderla
					para que, cuando sea posible, puedas corregir con tus propias
					herramientas aquello que te está enfermando.
				</p>
				<p>
					Me comprometo a escucharte con atención y tratarte con respeto. Me
					sentiré feliz y agradecido si logro ayudarte.
				</p>
			</div>
			<div style="padding: 5% 20%">
				<img
					src="../assets/images/mario_isamitt.jpg"
					style="width: 100%; border-radius: 15px; max-width: 700px !important"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	//import Gifs from '../components/home/Gifs.vue';
	//import Chronology from '../components/home/Chronology.vue';
	export default {
		name: 'Home',
		components: {
			//Gifs,
			//Chronology,
		},
		data() {
			return {
				selectedGif: 1,
				lastGif: 3,
				test: false,
			};
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
	};
</script>
<style scoped>
	.home {
		height: max-content;
		font-weight: bolder;
		font-size: 20px;
		text-align: center;
		padding-top: 5%;
	}
</style>
