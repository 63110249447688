import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#244179',
				secondary: '#3768a7',
				tertiary: '#84a7cd',
				quaternary: '#bed9f2',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
		},
	},
});
