<template>
	<v-app>
		<!-- <NavBar /> astaging -->
		<v-main>
			<router-view />
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
	import { mapActions } from 'vuex';
	//import NavBar from './components/navbar/NavBar.vue';
	import Footer from './components/footer/Footer.vue';
	export default {
		name: 'App',
		components: {
			//NavBar,
			Footer,
		},

		data: () => ({
			//
		}),
		created() {
			window.addEventListener('resize', this.resizeHandler);
			this.resizeHandler();
		},
		destroyed() {
			window.removeEventListener('resize', this.resizeHandler);
		},
		methods: {
			...mapActions({
				updateScreenWidth: 'layout/updateScreenWidth',
				updateScreenHeight: 'layout/updateScreenHeight',
			}),
			resizeHandler() {
				var w = document.documentElement.clientWidth;
				var h = document.documentElement.clientHeight;
				this.updateScreenWidth({ screenWidth: w });
				this.updateScreenHeight({ screenHeight: h });
			},
		},
	};
</script>
<style>
	@import url('https://fonts.googleapis.com/css?family=Karla');
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	@import url('https://fonts.googleapis.com/css?family=Satoshi');
	:root {
		--color-primary: #45474d;
		--color-secondary: #7c848f;
		--color-tertiary: #f7f4f9;
		--color-quaternary: #bed9f2;
		--color-accent: #8c9eff;
		--color-error: #b71c1c;
		--color-white: white;
	}
	#app {
		font-family: Satoshi, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: #f7f4f9;
	}
	.position-a {
		position: absolute !important;
	}
</style>
